<template>
  <div class="black" style="padding-top: 80px">
    <v-container class="py-0">
      <v-row>
        <v-col md="6" xl="4" offset-xl="2" lg="5" class="py-10 text-center text-md-left">
          <p class="section-title white--text fw-700">
            <span class="primary--text">CardPrêmios</span><br />
            Multibenefícios para a<br />
            empresa multiprêmios<br />para o colaborador
          </p>

          <p class="white--text my-10 fs21">
            Um <span class="primary--text fw-300">cartão prêmio</span> para todas as ocasiões
          </p>
          <v-layout>
            <v-flex class="white br10 d-flex align-center" md3>
              <a href="https://apps.apple.com/br/app/cardpr%C3%AAmios/id1597475498" target="_blank">
                <v-img src="~assets/images/app-store.png" class="br10"></v-img>
              </a>
            </v-flex>
            <v-flex class="white ml-5 br10 d-flex align-center" md3>
            <a href="https://play.google.com/store/apps/details?id=br.com.capptan.cardpremios" target="_blank">
              <v-img  src="~assets/images/google-play.png" class="br10"></v-img>
            </a>
            </v-flex>
          </v-layout>
        </v-col>
        <v-col md="6" xl="4" lg="7" class="bg-section mb-n15 mt-2 d-none d-md-block"> </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "SectionOne",
};
</script>
<style scoped>
.section-title {
  font-size: 42px;
  line-height: 59px;
  font-weight: bold;
}

.bg-section {
  overflow: visible;
  background-image: url("~assets/images/top-site.png");
  background-size: fill;
  background-position: center;
}
</style>
