<template>
  <v-container class="my-15 py-15 mb-5">
    <v-row v-for="(item, idx) in items" :key="idx" class="mb-10">
      <v-col md="6" xl="4" offset-xl="2" cols="12" class="text-center text-md-left">
        <span class="fs35 fw-800 primary--text">{{ item.title }}</span
        ><br />
        <span class="fs25 fw-800">{{ item.subtitle }}</span
        ><br />
        <span class="fs21 fw-300">{{ item.description }}</span>
      </v-col>
      <v-col md="6" xl="4" cols="12" data-aos="fade-left">
        <p class="fs21 fw-400" v-html="item.content"></p>
      </v-col>
    </v-row>
    <v-row justify="center" data-aos="zoom-in">
      <v-btn href="#contact" class="fw-800 primary text-transform-none" depressed x-large>
        Fale com nosso time
      </v-btn>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "SectionFour",
  computed: {
    items() {
      return [
        {
          title: "Para o RH",
          subtitle: "Tempo e Qualidade",
          description: "Gerencie premiações sem burocracia",
          content:
            "Ofereça premiações a um PIX<br>Dê diversas opções para o colaborador usar a premiação<br>Gerencie convênios sem burocracia, em um só lugar",
        },
        {
          title: "Para o colaborador",
          subtitle: "Flexibilidade e Autonomia",
          description: "Use sua premiação como quiser",
          content: `Compre em lojas físicas e virtuais<br>
            Use o cartão em todo o Brasil e no exterior<br>
            Contrate serviços como Uber, iFood e muito mais<br>
            Recarregue seu celular<br>
            Curta serviços de streaming<br>
            Compre créditos para jogos on-line
            `,
        },
      ];
    },
  },
};
</script>
