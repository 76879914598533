import Vue from "vue";
import VueSweetalert2 from "vue-sweetalert2";
import axios from "axios";
import VueAxios from "vue-axios";
import VueMask from "v-mask";
import vuetify from "./plugins/vuetify";
import App from "./App.vue";
import "styles/app.css";

Vue.config.productionTip = false;
Vue.use(VueSweetalert2, {
  confirmButtonColor: "#DDAB2E",
});
Vue.use(VueAxios, axios);
Vue.use(VueMask);

new Vue({
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
