<template>
  <div class="bg-section py-0">
    <v-container>
      <v-row>
        <v-col cols="5" lg="5" xl="4" offset-xl="2" data-aos="zoom-in-up">
          <v-img src="~assets/images/presenter01.png" class="mt-n15" />
        </v-col>
        <v-col md="7" xl="4" lg="6" offset-lg="1" offset-xl="0" class="py-10">
          <h1 class="fw-900" id="about-app">
            Melhore a experiência dos<br />colaboradores com CardPrêmios
          </h1>
          <div
            class="d-flex align-start col-md-8"
            v-for="(item, idx) in items"
            :key="idx"
            data-aos="fade-up-left"
          >
            <v-icon color="primary">mdi-check</v-icon>
            <p class="ml-2" v-html="item"></p>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "SectionFive",
  computed: {
    items() {
      return [
        `Gestão simples e 100% digital dos valores de<br>premiações: tudo sob controle.`,
        `Melhore seu ambiente de trabalho com<br>premiações flexíveis.`,
        `Ofereça poder de escolha para o colaborador:<br>ele usa seu prêmio como e onde quiser.`,
      ];
    },
  },
};
</script>
<style scoped>
.bg-section {
  background: #fbfbfb;
}
</style>
