<template>
  <div class="menu-area white">
    <v-container class="py-0">
      <v-row class="col-xl-8 mx-auto">
        <v-app-bar color="transparent" elevation="0" light>
          <v-col md="2" cols="2" class="pl-0">
            <img src="~assets/images/logo.png" alt="logomarca" />
          </v-col>

          <v-spacer></v-spacer>

          <v-btn
            v-for="(menu, idx) in menus"
            :key="idx"
            :href="menu.to"
            color="transparent"
            class="text-transform-none fw-300 d-none d-md-inline-flex"
            depressed
          >
            {{ menu.name }}</v-btn
          >
          <v-btn
            href="#contact"
            color="transparent"
            class="primary--text d-none d-md-inline-flex btn-want-proposal fw-800"
            depressed
          >
            Quero uma proposta</v-btn
          >

          <v-app-bar-nav-icon
            class="d-block d-md-none"
            @click.stop="drawer = !drawer"
          ></v-app-bar-nav-icon>
        </v-app-bar>

        <v-navigation-drawer v-model="drawer" fixed floating>
          <div class="text-center py-5 grey lighten-5">
            <img src="~assets/images/logo.png" style="width: 50%" alt="logomarca" />
          </div>

          <v-list nav dense>
            <v-list-item-group @change="drawer = false" active-class="primary--text">
              <v-list-item
                v-for="(menu, idx) in menus"
                :key="idx"
                :href="menu.to"
                color="transparent"
                class="text-transform-none fw-300"
                depressed
              >
                {{ menu.name }}
              </v-list-item>
              <v-list-item
                href="#contact"
                color="transparent"
                class="primary--text fw-800"
                depressed
              >
                QUERO UMA PROPOSTA
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-navigation-drawer>
      </v-row>
    </v-container>
  </div>
</template>
<script>
export default {
  name: "NavMenu",
  computed: {
    menus() {
      return [
        {
          name: "Conta e Cartão",
          to: "/#account-and-card",
        },
        {
          name: "Sobre o App",
          to: "/#about-app",
        },
        {
          name: "Central de Ajuda",
          to: "/#help",
        },
      ];
    },
  },
  data: () => ({
    drawer: false,
  }),
};
</script>

<style scoped>
.btn-want-proposal {
  font-weight: 800;
}

.menu-area {
  position: fixed;
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  z-index: 10;
  box-shadow: 1px 0.2px 1px #dedede;
}
</style>
