<template>
  <div>
    <v-container class="mt-15 mb-5 black white--text br14 col-xl-8">
      <v-row>
        <v-col cols="8" class="pa-10" data-aos="flip-right">
          <span class="fs42"> CardPrêmios é hub de premiações </span>
          <span class="fs21"> Concentramos todas as vantagens em um único cartão </span>
        </v-col>
        <v-col md="4" data-aos="fade-up-left">
          <v-img src="~assets/images/card.png" class="p-absolute section-card" />
        </v-col>
      </v-row>
    </v-container>
    <v-container class="col-xl-8">
      <v-row>
        <v-col class="d-none d-md-block" data-aos="fade-right">
          <span class="fs25">Cartão Pré-Pago + Conta Digital + Gestão no App + Gift Card</span>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "SectionThree",
};
</script>
<style scoped>
.section-card {
  margin-top: -5rem;
}
</style>
