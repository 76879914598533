<template>
  <v-container class="mb-15">
    <v-row class="col-xl-8 mx-auto">
      <v-col md="4" cols="12" class="text-center text-md-left">
        <span class="font-weight-bold fs28 lh41">
          Transforme sua<br />
          empresa com <span class="primary--text">CardPrêmios</span>
        </span>
        <br />
        <v-layout class="mt-5 justify-center justify-md-start">
          <v-flex class="white br10 d-flex align-center border" xs3 md4>
            <v-img src="~assets/images/app-store.png" class="br10"></v-img>
          </v-flex>
          <v-flex class="white ml-5 br10 d-flex align-center border" xs3 md4>
            <v-img src="~assets/images/google-play.png" class="br10"></v-img>
          </v-flex>
        </v-layout>
      </v-col>
      <v-col md="4" cols="12">
        <v-list dense>
          <v-list-item
            :href="item.to"
            class="rounded"
            v-for="(item, id) in footerMenuItems"
            :key="id"
          >
            <span class="fw-300 fs15 rounded">{{ item.title }}</span>
          </v-list-item>
        </v-list>
      </v-col>
      <v-col md="4" cols="12" class="d-flex flex-column align-md-end align-center">
        <img src="~assets/images/logo.png" alt="logomarca" height="26" />
        <div class="d-flex flex-wrapx align-center mt-5">
          <img src="~assets/icons/icon-instagram.svg" alt="instagram" class="mr-2" />
          <img src="~assets/icons/icon-facebook.svg" alt="facebook" class="mr-2" />
          <span class="fs16">meajuda@cardpremios.com.br</span>
        </div>
        <div class="mt-15">
          <img src="~assets/images/powered-by.png" alt="logo" class="mr-2" />
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "SectionNine",
  computed: {
    footerMenuItems() {
      return [
        {
          title: "Transparência",
          to: "#",
        },
        {
          title: "Política de Privacidade",
          to: "#",
        },
        {
          title: "Termos e Condições de Uso do APP",
          to: "#",
        },
        {
          title: "Termos e Condições de Uso do Cartão",
          to: "#",
        },
      ];
    },
  },
};
</script>
<style scoped>
.lh41 {
  line-height: 41px;
}
.border {
  box-shadow: 0px 5px 5px #eee;
}
</style>
