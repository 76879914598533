<template>
  <v-container class="pt-15 my-15">
    <v-row class="text-center" justify="center">
      <v-col cols="12" data-aos="fade-down">
        <span class="fs25 fw-100"
          >Tá na hora de simplificar os processos de premiação da sua empresa</span
        >
      </v-col>
      <v-col cols="12" class="mb-10" data-aos="fade-down">
        <span class="fs50 fw-800 section-title">
          Controle e pague premiações de um jeito <br />
          <span class="primary--text"> prático e rápido.</span>
        </span>
      </v-col>
      <v-btn
        href="#contact"
        class="fw-800 primary text-transform-none"
        data-aos="fade-up"
        depressed
        x-large
      >
        Quero saber como funciona!
      </v-btn>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "SectionTwo",
};
</script>
<style scoped>
.section-title {
  line-height: 73px;
}
</style>
