<template>
  <v-container class="py-15">
    <v-row>
      <v-col cols="12" class="text-center" data-aos="flip-left">
        <h1 class="fw-800" id="contact">
          Simplifique processos, controle e pague<br />
          premiações sem burocracia
          <span class="primary--text">com CardPrêmios</span>
        </h1>
      </v-col>
    </v-row>
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-row class="justify-center col-md-8 col-xl-6 mx-auto">
        <v-col cols="12">
          <v-text-field
            label="Nome:"
            v-model="payload.name"
            :rules="[requiredRule]"
            placeholder="Ex: Maria Santos"
            required
          />
        </v-col>
        <v-col md="6" cols="12">
          <v-text-field
            label="Telefone:"
            :rules="[requiredRule]"
            v-model="payload.phone"
            v-mask="'(##) # ####-####'"
            placeholder="(11) 9 9123-4567"
          />
        </v-col>
        <v-col md="6" cols="12">
          <v-text-field
            label="E-mail:"
            :rules="[emailRule, requiredRule]"
            v-model="payload.email"
            placeholder="Ex: meajuda@cardpremios.com.br"
            required
          />
        </v-col>
        <v-col md="6" cols="12">
          <v-text-field
            label="Nome da Empresa:"
            :rules="[requiredRule]"
            v-model="payload.companyName"
            required
          />
        </v-col>
        <v-col md="6" cols="12">
          <v-text-field
            label="Número de funcionários:"
            type="number"
            min="1"
            v-model="payload.employeesAmount"
            placeholder="Ex: 50"
            :rules="[minOneEmployeeRule]"
          />
        </v-col>
        <v-col cols="12">
          <v-textarea
            label="Mensagem:"
            :rules="[requiredRule]"
            required
            v-model="payload.message"
            placeholder="Descreva sua mensagem para contato"
          />
        </v-col>
        <v-col cols="12" class="text-center" data-aos="flip-up">
          <v-btn color="primary" @click.prevent="send()" :disabled="loading" x-large
            >Solicitar contato
            <v-progress-circular
              indeterminate
              size="30"
              color="primary"
              v-if="loading"
            ></v-progress-circular>
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
export default {
  name: "SectionEight",
  data: () => ({
    valid: true,
    loading: false,
    payload: {
      name: "",
      phone: "",
      email: "",
      companyName: "",
      employeesAmount: 1,
      message: "",
    },
  }),
  computed: {
    minOneEmployeeRule() {
      return (v) => v >= 1 ?? "A empresa precisa possuir ao menos 1 funcionário";
    },
    emailRule() {
      return (v) =>
        !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || "E-mail inválido";
    },
    requiredRule() {
      return (v) => String(v).trim().length > 0 ?? "Campo obrigatório";
    },
  },
  methods: {
    async send() {
      if (await this.$refs.form.validate()) {
        this.loading = true;
        this.$http
          .post("https://api.hom.k8s.cardpay.com.br/common/interested", {
            ...this.payload,
            phone: this.payload.phone.replace(/\D/g, ""),
            userType: "Vibbe",
          })
          .then(() => {
            this.$swal("Sua mensagem foi enviada com sucesso!");
            this.$refs.form.reset();
          })
          .catch(() => {
            this.$swal("Falha ao enviar sua mensagem, por favor tente novamente.");
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
  },
};
</script>
